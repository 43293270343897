.my-2{
    margin-top: 0.5rem; /* 8px */
    margin-bottom: 0.5rem;
}
.my-3{
    margin-top: 0.8rem; /* 8px */
    margin-bottom: 0.8rem;
}
.mt-4{
    margin-top: 4rem;
}
.mt-6px{
    margin-top: 6px;
}
.space-x-8{
    margin-left: 2rem;
}
.items-center{
    align-items: center;
}
.address-text{
    font-size: medium;
}
.dialog-button-action{
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
}
.w-full{
    width: 100%;
}
.product-header{
    font-size: 20px;
    color: black;
    font-weight: 400;
}

.btn-hg{
    width: 100%;
}